<template>
  <div class="header wf-section">
    <div class="container w-container">
      <div class="w-layout-grid grid">
        <div id="w-node-_5f934690-e0f2-a5e0-21a5-fc37ab4eab5a-ab4eab57" class="zone-logo">
          <a href="https://pautions.fr" target="_blank">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="" class="image-5">
          </a>
        </div>
        <div id="w-node-f3534332-0644-55d4-e199-dbac2c8ba377-ab4eab57" class="div-zone-connexion-header">
          <div class="zone-nom-parcours">
            <div class="cercle-id-parcours">
              <p class="id-nom-parcours">{{initial}}</p>
            </div>
            <p class="nom-id-parcours">{{$store.api.user.prenom}} {{$store.api.user.nom}}<br></p>
          </div>
          <a href="javascript:void(0)" @click="$store.api.destroyToken()" class="bouton-deconnexion w-inline-block"><img :src="require('@/assets/images/ic_deconnexion.svg')" loading="lazy" width="18" alt="" class="ic-deconnexion">
            <p class="txt-deconnexion">se déconnecter</p>
          </a>
        </div>
        <div id="w-node-_5f0f4c77-f7f9-904a-5a32-dc520887e6e2-ab4eab57" class="div-call-assistance">
          <a href="tel:+33970386826" class="link-bloc-tel w-inline-block"><img :src="require('@/assets/images/ic_tel.svg')" loading="lazy" width="20" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  computed:{
    initial(){
      var tmp = ""
      if(this.$store.api.user.prenom != null){
        tmp += this.$store.api.user.prenom.toUpperCase()[0]
      }
      if(this.$store.api.user.nom != null){
        tmp += this.$store.api.user.nom.toUpperCase()[0];
      }
      return tmp;
    }
  },
  mounted() {
    //this.$store.api.initMenuMobile();
  }
}
</script>
<style lang="scss" scoped>
</style>

<template>
  <div v-if="ready">
    <Menu/>
    <section class="top-blocs wf-section">
    <div class="w-layout-blockcontainer content_top_bloc_dashboard w-container">
      <div class="titre_chat">
        <div class="titre_dash_loc_nonsouscris">
          <p class="titre_dash_loc_non_souscrit">Bonjour {{ user.prenom }}, nous sommes le {{ now }}.</p>
          <p class="p_txt_field_form_v2">Bienvenue sur votre <span class="text-span-41">espace Pautions</span>.</p>
        </div>        
      </div>
      <div class="w-layout-grid grid_top_bloc_dashboard">
        <div id="w-node-ccb9a4a0-7c02-6086-5207-0b1095dbbb5b-2801d06c" class="div_top_bloc_attestation">
          <div class="w-embed w-script">
            
            <div class="elfsight-app-10657c14-45ca-43bf-a7c3-d2f8791b8664"></div>
          </div>
          <div class="div-block-29 violet1">
            <p class="titre_top_bloc">Vous êtes en recherche d&#x27;un logement ?</p>
          </div>
          <div class="div-block-28">
            <ol role="list" class="list">
              <li class="liste_bloc_ap">Récupérez votre attestation provisoire ci-dessous et ajoutez-la à votre dossier de location.</li>
              <li class="liste_bloc_ap">Une fois votre logement trouvé, obtenez l&#x27;accord du propriétaire / gestionnaire.</li>
              <li class="liste_bloc_ap">Avant de souscrire, assurez-vous d&#x27;avoir récupéré les coordonnées du propriétaire ou gestionnaire.
                &nbsp;<a href="#"><span id="infos_coordonnees_proprio" class="text-span-44"><strong class="bold_info_proprio">i</strong></span></a>
              </li>
            </ol>
          </div>
          <div class="div-block-30">
            <a :href="souscription.docs.attestation" target="_blank" class="btn_01_bloc_ap w-button">Je récupère mon attestation</a>
          </div>
        </div>
        <div id="w-node-_0d72d2e9-d484-96a6-bb94-adf8664eeed5-2801d06c" class="div_top_bloc_souscription">
          <div class="div-block-29">
            <p class="titre_top_bloc">Vous avez trouvé un logement ?</p>
          </div>
          <div class="div-block-28">
            <ol role="list" class="list">
              <li class="liste_bloc_ap">Vous avez l&#x27;accord du propriétaire ou gestionnaire pour souscrire à l&#x27;assurance Zéro Dépôt.</li>
              <li class="liste_bloc_ap">Vous disposez des coordonnées du propriétaire ou gestionnaire ainsi que de l&#x27;adresse de votre futur logement.</li>
            </ol>
          </div>
          <div class="div-block-30">
            <a :href="`/#/v2/zero-depot?uuid=${souscription.uuid}`" class="btn_01_bloc_ap w-button">Je finalise ma souscription</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="section-selectra">
    <div class="content_selectra w-container">
      <div id="w-node-f0479d92-a1d1-313a-4b35-3d7108eab266-2801d06c" class="div_selectra">
        <div class="titre_selectra_dashboard">
          <div class="div-block-33">
            <div class="div-block-34"><img :src="require('@/assets/images/ic_box.svg')" loading="lazy" alt="" class="ic_offre_selectra">
              <p class="titre_selectra_bloc"><span class="text-span-45">Déménagez sans stress, économisez avec Selectra !</span></p>
            </div>
            <p class="tag_selectra">Offre déménagement</p>
          </div>
          <div class="div-block-35">
            <p class="paragraph-25">En partenariat avec</p><img :src="require('@/assets/images/logo_selectra.svg')" loading="lazy" alt="" class="image-12">
          </div>
        </div>
        <div class="div-block-31">
          <div>
            <p class="txt-bloc-bandeau-selectra">Vous déménagez bientôt ? Ne manquez pas l&#x27;occasion de réduire vos factures !</p>
            <p class="txt-bloc-bandeau-selectra_2">Faites tout en un appel téléphonique et réalisez des économies.</p>
            <div class="listing_selectra">
              <div class="listing_check_selectra"><img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="" class="ic_check-selectra">
                <p class="txt_list_selectra">Transfert de contrats d&#x27;énergie</p>
              </div>
              <div class="listing_check_selectra"><img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="" class="ic_check-selectra">
                <p class="txt_list_selectra">Meilleures offres Internet</p>
              </div>
              <div class="listing_check_selectra"><img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="" class="ic_check-selectra">
                <p class="txt_list_selectra">Offres d&#x27;assurances habitation</p>
              </div>
            </div>
          </div>
        </div>
        <div class="div-block-32">
          <a href="tel:+33971078969" class="btn_bandeau_call_selectra w-button">Appelez le 09 71 07 89 69</a>
          <div>
            <p class="txt_info_selectra">Service gratuit Selectra noté ⭐️⭐️⭐️⭐️⭐️ (<a href="https://www.ekomi.fr/avis-clients-selectra.html" target="_blank" rel="nofollow">4,8/5</a>)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-etapes-dash wf-section">
    <div class="content_etapes_dashboard-copy w-container">
      <div id="w-node-f0479d92-a1d1-313a-4b35-3d7108eab266-2801d06c" class="div_etapes_dashboard">
        <div class="titre_etapes_dashboard"><img :src="require('@/assets/images/ic_documents.svg')" loading="lazy" alt="" class="ic_book_etapes">
          <p class="titre_etapes_bloc">Mes étapes</p>
        </div>
        <div class="notifications_etapes_dash">
          <div class="notifications_terminees" v-for="(e, i) in eventsF" :key="i">
            <img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="" class="ic_notif_terminee">
            <div class="txt_notif_terminees">
              <p class="txt_notification_terminees">{{ e.titre }}</p>
              <p class="heure_notification">{{ $store.api.timestampToDate(e.date_create, false) }}</p>
            </div>
          </div>          
          <div v-for="(e) in eventsN" :key="e.id">
            <div class="notifications_en_cours">
              <img :src="require('@/assets/images/ic_arrow_red.svg')" loading="lazy" alt="" class="ic_notif_en_cours">
              <p class="txt_notification_en_cours">
                <span class="text-span-43"> {{ e.titre }} </span>
              </p>
            </div>
            <div class="div_btn_notif_etapes">
              <a :href="e.url" class="btn_notifs_etapes w-button">Cliquez ici</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="zone-boutons">
    <div class="w-layout-blockcontainer content_bandeau_call w-container">
      <div class="div_zone_boutons">
        <div id="w-node-d7bd8c2b-6f20-96fd-8991-2aa98e0b8c56-2801d06c" class="div_bandeau_call">
          <p class="txt-bloc-bandeau-call">Vous avez des questions sur comment finaliser votre souscription ? <span class="text-span-40">Contactez notre équipe par téléphone ou par WhatsApp directement.</span></p>
          <div class="div-block-36">
            <a href="tel:+33970386826" class="btn_bandeau_call w-button">09 70 38 68 26</a>
            <div class="html-embed w-embed w-script">              
              <div class="elfsight-app-1e5ffcdd-e861-46a9-932a-a1f3ad9ed119"></div>
            </div>
          </div>
        </div>
        <div id="w-node-fe01d5f3-99b0-27ab-2fb1-d9d7fb3f5f34-2801d06c" class="div_bandeau_ap_regen">
          <p class="txt-bloc-bandeau-call">La durée de validité de votre attestation provisoire arrive à échéance ? Vous avez modifié vos coordonnées ?</p>
          <a href="javascript:void(0)" @click="regenerate()"  class="btn_ap_regen w-button">Je regénère mon attestation provisoire</a>
        </div>
      </div>
    </div>
  </section>  
  <div class="zone-coordonnees-dash wf-section">
    <div class="content_coordonnees_dash w-container">
      <div id="w-node-e5209399-c42b-8660-32da-eea98b2548b4-2801d06c" class="div_coordonnees_dash">
        <p class="txt-bloc-bandeau-call">Vous souhaitez modifier vos coordonnées, le montant du loyer ou le type de location désirée ?<span class="text-span-40"></span></p>
        <div class="btn_affichage_coordonnees">
          <a href="javascript:void(0)" v-if="collapse_info === false" @click="collapse_info = !collapse_info" class="btn_modif_coordonnees voir w-button">Voir mes coordonnées</a>
          <a href="javascript:void(0)" v-else @click="collapse_info = !collapse_info" class="btn_modif_coordonnees w-button">Masquer mes coordonnées</a>
        </div>
      </div>
      <div id="w-node-_5f0350c8-e616-8a68-e248-6775cf9d27a5-2801d06c" class="coordonnees_dash_loc" v-if="collapse_info === true">
        <div class="content_coordonnees_dash_loc">
          <div class="btn_modification_coordonnees">
            <div class="div_input_modifier">
              <a href="javascript:void(0)" @click="saveForm()" :class="form_lock ? 'modifier' : 'sauvegarder'" class="btn_modifs_coordonnees w-button">{{ form_lock ? 'Modifier' : 'Sauvegarder' }}</a>              
            </div>
          </div>
          <div class="div_content_infos">
            <div class="form_infos_content w-form">
              <form ref="user_form" id="wf-form-Form-info-dashboard" name="wf-form-Form-info-dashboard" data-name="Form info dashboard" method="get" class="form_infos_content">
                <div class="div_recap_infos dashboard_left">
                  <p class="paragraph etape coordonnees">mes informations</p>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Nom &amp; Prénom : </p>
                    <input type="text" :disabled="form_lock" v-model="user.nom" class="champs_recap_infos _100 dashboard w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom" id="Nom" required="">
                    <input type="text" :disabled="form_lock" v-model="user.prenom" class="champs_recap_infos _100 dashboard w-input" maxlength="256" name="Prenom" data-name="Prenom" placeholder="Prenom" id="Prenom" required=""> 
                  </div>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Email :</p>
                    <input type="email" :disabled="form_lock" v-model="user.email" class="champs_recap_infos _100 dashboard w-input" maxlength="256" name="Email" data-name="Email" placeholder="monmail@mail.com" id="Email" required=""> 
                  </div>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Téléphone :</p>
                    <div class="div-block-24">
                      <input type="tel" :disabled="form_lock" v-model="user.telephone" class="champs_recap_infos _100 dashboard w-input" maxlength="10" minlength="10" name="Telephone" data-name="Telephone" placeholder="0102030405" id="Telephone" required="">
                    </div>
                  </div>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Date de naissance :</p>
                    <div class="div-block-24">
                      <input type="date" :disabled="form_lock" v-model="user.date_naissance" :max="getDate18" class="champs_recap_infos _100 dashboard w-input  v2-date" maxlength="256" name="Date-de-Naissance" data-name="Date de Naissance" placeholder="11/01/2001" id="Date-de-Naissance" required="">
                    </div>
                  </div>
                </div>
                <div class="div_recap_infos dashboard_right">
                  <p class="paragraph etape logement">logement recherché</p>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Type de logement :</p>
                    <select id="Type-logement" v-model="souscription.meublee" :disabled="form_lock" name="Type-logement" data-name="Type logement" required="" class="champs_recap_infos _100 dashboard w-select">
                      <option :value="false">Vide</option>
                      <option :value="true">Meublée</option>
                    </select>
                  </div>
                  <div class="div_info_champs">
                    <p class="p_txt_field_form_v2 light dashboard">Loyer estimé :</p>
                    <input type="number" v-model="souscription.loyer" :disabled="form_lock" class="champs_recap_infos _100 dashboard euro w-input" max="3000" name="Montant-loyer" data-name="Montant loyer" placeholder="1 200€" id="Montant-loyer" required=""> 
                  </div>
                  <div class="div_info_champs" v-if="souscription.apt_ref != null">
                    <p class="p_txt_field_form_v2 light dashboard code_appartement">Code appartement (facultatif) :</p>
                    <div class="div-block-24">
                      <input type="text" v-model="souscription.apt_ref" :disabled="form_lock" class="champs_recap_infos _100 dashboard w-input" maxlength="256" name="Code-appartement" data-name="Code appartement" placeholder="" id="Code-appartement">
                    </div>
                  </div>
                  <!-- <div class="div_info_champs azp">
                    <p class="p_txt_field_form_v2 light dashboard azp">Montant assurance Zéro Dépôt : {{$store.api.prix(souscription.prix_mensuel)}}/mois</p>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu/NonUtilisateur';
export default {
  name: "Home",
  components: {
    Menu
  },
  data(){
    return {
      ready:false,
      generate:false,
      events: [],
      souscription:{},
      user:{},
      form_lock: true,
      load:false,
      collapse_info: false
    }
  },
  watch:{
   'souscription.loyer'(){      
      // this.price();
    },
    'souscription.meublee'(){
      // this.price();
    }
  },
  computed:{
    eventsN(){
      return this.events.filter(e => e.finish === false)
    },
    eventsF(){
      return this.events.filter(e => e.finish === true).reverse()
    },
    getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day
      return `${year}-${month}-${day}`
    },  
    now(){
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      return dd + '/' + mm + '/' + yyyy;
    }
  },
  beforeMount() {
    this.$store.api.ajax('/home/init', null, res => {
      if(res.status === true){
        this.events = res.data.events;
        this.souscription = res.data.souscription;
        this.user =res.data.user
        this.ready = true;
      }
    })
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
  methods:{
    regenerate(){
      if(this.generate === true){
        return false;
      }
      this.generate = true;
      this.$store.api.ajax('/zero-depot/attestation/'+this.souscription.uuid, null, res => {
        if(res.status === true){
          window.open(this.souscription.docs.attestation, '_blank');
        }
        this.generate = false;
      })
    },
    checkForm(){
      var res = true;
      if(this.$refs.user_form.reportValidity() === false){
        res = false;
      }
      return res;
    },
    saveForm(){         
      if(this.form_lock){
        this.form_lock = false;
      }else {
        if(this.load === true || !this.checkForm()){
          return false;
        }
        this.load = true;
        var params = {
          user: this.user,
          souscription: this.souscription
        };
        this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
          if(res.status === true){
            this.souscription = res.data.souscription;
            this.form_lock = true;
            this.load = false;
          }
        });  
      }
    },
    price(){
      this.$store.api.ajax('/zero-depot/prix', this.souscription, res => {
        if(res.status === true){
         this.souscription.prix_mensuel = res.data;
         this.souscription.prix_annuel = res.data === null ? null : this.souscription.prix_mensuel * 11;          
        }
      })
    },
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
.w-input, .w-select {
  color: #242189 !important;
}

.w-input[disabled], .w-select[disabled] {
   background-color: white !important;
   color: #7170ab;
}
@supports (-webkit-touch-callout: none) {
   .w-input[disabled] , .w-select[disabled]{
      background-color: white !important;
      color: #19187e !important;
   }
 }
 @media screen and (max-width: 767px){
  .v2-date {    
    width: 550px !important;
  }
  input.v2-date::-webkit-date-and-time-value {
    text-align: left !important;
  }
}
.euro.w-input {
  background-image:  url('~@/assets/images/ic_euro_2.svg');
  background-position: 95%;
  text-align: left !important;
  background-repeat: no-repeat;
  background-size: auto;
}
.coordonnees_dash_loc {
  display: block;
}
</style>
